$default-color: #d23c1c;
$black:#000;
$white:#FFFFFF;
$color1:#444444;
$color2:#E14A3B;
$color3:#E6E6E6;
$color4:#2C3D4B;
$color5:#466178;
$color6:#373337;
$color7:#FAFAFA;
$color8:#E8E8E8;
$color9:#F8F8F8;
$color10:#E7EBEC;
$color11:#F1F1F1;
$color12:#515151;
$color13:#7B2520;
$color14:#F7F7F7;
$color15:#E9E9E9;
$color16:#666666;
$color17:#CECECE;
$color18:#ECECFB;
$color19:#F5F5F5;
$color20:#466078;
$color21:#1F1F1F;
$color22:#909090;
$color23:#FDFDFD;
$color24:#465F78;
$color25:#F2F2F2;
$color26:#F9F9F9;
$color27:#212121;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  1200px !default;
$screen-lg:                  1200px !default;
